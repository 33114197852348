import { useEffect, useState } from "react";
import { useGetUserProfileMutation, useGptBlueSocialShareMutation, useShareLinkedinPostMutation, useShareTwitterPostMutation, useUserLinkedinValidationMutation, useUserTwitterValidationMutation } from "../../../api-integration/secure/secure";
import {  getTranslatedText, hideModal, initializeModal } from "../../../util/util";
import { useDispatch, useSelector } from "react-redux";
import { fullPageLoader, updateAlertMessage, updateUser } from "../../../api-integration/commonSlice";
import TooltipComponent from "../bootstrap-component/tooltip-component";
import usePageTranslation from "../../../hooks/usePageTranslation";
import EditTranslationIcon from "../edit-translation-icon/EditTranslationIcon";

interface SocialSharePostProps {
    id: string;
    socialShareName: string;
    socialShareContent: string;
    socialDescription: string;
    socialImage?: string;
    postingOption: string;
    shareObjectId: string;
    shareObject?: string;
    setSocialShareName: (name: string) => void;
    promptURL?: string;
    userPromptId?: number;
}

const SocialSharePost: React.FC<SocialSharePostProps> = (props) => {
    const dispatch = useDispatch();
    const PageTranslation = usePageTranslation();
    const {TranslationEditFlag} = useSelector((state: any) => state.commonSlice);

    const [socialShareId, setSocialShareId] = useState<number>(0);   // 1 = linkedin, 2 = WhatsApp, 3 = Twitter, 4 = email
    const [shareContent, setShareContent] = useState<string>('');
    const [socialShareVisibility, setSocialShareVisibility] = useState<number>(0);
    // This state will tell us what the URL will be if the user is not connected so that we can direct the user to that particular URL
    const [notConnectedShareURL, setNotConnectedShareURL] = useState<string>('');
    const [isSocialPostMaxLengthErrorFlag, setIsSocialPostMaxLengthErrorFlag] = useState<boolean>(false);   // This state will tell if the post exceeds maxLength or not
    const [socialPostMaxLengthKey, setSocialPostMaxLengthKey] = useState<string>('');               // This state will provide the appropriate message when the isSocialPostMaxLengthError is true

    // This state will tell us if the validation API was successful or not (This will handle all the share icons validation)
    // We are using null as when the API is loading, then at that time, we do not want to show post or connect button
    const [isUserValidationSuccess, setIsUserValidationSuccess] = useState<boolean | null>(null);

    // This state will be used to show the message if the user is not connected(This will handle all the share icons message when user is not connected)
    const [userNotConnectedMessage, setUserNotConnectedMessage] = useState<string>('');
    
    // Linkedin Validation API
    const[userLinkedinValidateAPI, {data: userLinkedinValidateData, isLoading: isUserLinkedinValidateIsLoading, isSuccess: isUserLinkedinValidateSuccess, isError: isUserLinkedinValidateError, error: userLinkedinValidateError}] 
    = useUserLinkedinValidationMutation();

    // Twitter Validation API
    const [userTwitterValidateAPI, {data: userTwitterValidateData, isLoading: isUserTwitterValidateIsLoading, isSuccess: isUserTwitterValidateSuccess, isError: isUserTwitterValidateError, error: userTwitterValidateError}] 
    = useUserTwitterValidationMutation();

    // API for Social icons except LinkedIn
    const [gptBlueSocialShareAPI, { data: socialShareData, isSuccess: isSocialShareSuccess, isError: isSocialShareError, error: socialShareError}] 
    = useGptBlueSocialShareMutation();

    // API for getting user details
    const [getUserDetailsAPI, { data: userInfo, isSuccess: isUserDetailSuccess, isError: isUserDetailError, error: userDetailError  }] 
    = useGetUserProfileMutation();

    // Linkedin Post API
    const [shareLinkedinPostAPI, {data: shareLinkedinPostData, isSuccess: isShareLinkedinPostSuccess, isError: isShareLinkedinPostError, error: shareLinkedinPostError}] 
    = useShareLinkedinPostMutation();

    // Twitter Post API
    const [shareTwitterPostAPI, {data: shareTwitterPostData, isSuccess: isShareTwitterPostSuccess, isError: isShareTwitterPostError, error: shareTwitterPostError}] 
    = useShareTwitterPostMutation();

    useEffect(() => {
        // Call the initializeModal function on mount/update
        initializeModal(props?.id,resetModalValues);
    
        return () => {
            // Call the initializeModal function on unmount/cleanup
            initializeModal(props?.id,resetModalValues);
        };
    }, [props?.id]);

    useEffect(() => {
        if(isShareTwitterPostSuccess) {
            if(shareTwitterPostData?.success === true) {
                dispatch(fullPageLoader(true));
                getUserDetailsAPI({accountType: 'user'});
            }
            else {
                dispatch(fullPageLoader(false));
                dispatch(updateAlertMessage({ status: 'error', message: shareTwitterPostData?.message }));
            }
        }
        else if(isShareTwitterPostError || shareTwitterPostError) {
            dispatch(fullPageLoader(false));
            dispatch(updateAlertMessage({ status: 'error', message: shareTwitterPostData?.message }));
        }
    },[isShareTwitterPostSuccess, isShareTwitterPostError, shareTwitterPostError]);

    useEffect(() => {
        if(isShareLinkedinPostSuccess) {
            if(shareLinkedinPostData?.success === true) {
                dispatch(fullPageLoader(true));
                getUserDetailsAPI({accountType: 'user'});
            }
            else {
                dispatch(fullPageLoader(false));
                dispatch(updateAlertMessage({ status: 'error', message: shareLinkedinPostData?.message }));
            }
        }
        else if(isShareLinkedinPostError || shareLinkedinPostError) {
            dispatch(fullPageLoader(false));
            dispatch(updateAlertMessage({ status: 'error', message: shareLinkedinPostData?.message }));
        }
    },[isShareLinkedinPostSuccess, isShareLinkedinPostError, shareLinkedinPostError]);

    useEffect(() => {
        if(isSocialShareSuccess) {
            if(socialShareData?.success === true) {
                dispatch(fullPageLoader(true));
                getUserDetailsAPI({accountType: 'user'});
            }
            else {
                dispatch(fullPageLoader(false));
                dispatch(updateAlertMessage({ status: 'error', message: socialShareData?.message }));
            }
        }
        else if(isSocialShareError || socialShareError) {
            dispatch(fullPageLoader(false));
            dispatch(updateAlertMessage({ status: 'error', message: socialShareData?.message }));
        }
    },[isSocialShareSuccess, isSocialShareError, socialShareError]);

    useEffect(() => {
        if (isUserDetailSuccess) {
            dispatch(fullPageLoader(false));
            let user = JSON.parse(localStorage.getItem('user') as string);
            if(userInfo?.user?.totalCCPoints){
                user.totalCCPoints = userInfo?.user?.totalCCPoints;
                dispatch(updateUser(user));
                localStorage.setItem('user', JSON.stringify(user));
            }
        }
        if (isUserDetailError || userDetailError) {
            dispatch(fullPageLoader(false));
        }
    }, [isUserDetailSuccess, isUserDetailError, userDetailError]);

    useEffect(() => {
        if(props?.socialShareName === 'LinkedIn') {
            setSocialShareId(1);
        }
        else if(props?.socialShareName === 'WhatsApp') {
            setSocialShareId(2);
            setIsUserValidationSuccess(true);   // Right now we do not have any validation API to test so for now it will be true
        }
        else if(props?.socialShareName === 'Twitter') {
            setSocialShareId(3);
        }
        else if(props?.socialShareName === 'Email') {
            setSocialShareId(4);
            setIsUserValidationSuccess(true);   // Right now we do not have any validation API to test so for now it will be true
        }
        setShareContent(props?.socialShareContent);
    },[props?.socialShareName]);

    useEffect(() => {
        if(props?.socialShareName === 'LinkedIn') {
            userLinkedinValidateAPI({});
        }
        else if(props?.socialShareName === 'Twitter') {
            userTwitterValidateAPI({});
        }
        // For other validation API's we can call them here
    },[props?.socialShareName]);

    useEffect(() => {
        if(isUserLinkedinValidateSuccess) {
            if(userLinkedinValidateData?.success === "true") {
                setIsUserValidationSuccess(true);
                setNotConnectedShareURL("");
                setUserNotConnectedMessage("");
            }
            else if(userLinkedinValidateData?.success === "false") {
                // Redirect to LinkedIn URL with additional query parameter
                const linkedinUrl = process.env.REACT_APP_LINKEDIN_URL;
                const redirectUrl = `${linkedinUrl}&ObjectName=SocialShare`;  // Append query parameter

                setIsUserValidationSuccess(false);
                setNotConnectedShareURL(redirectUrl);
                setUserNotConnectedMessage(userLinkedinValidateData?.message);
            }
        }
        if(isUserLinkedinValidateError || userLinkedinValidateError) {
            hideModal(props?.id);
            dispatch(updateAlertMessage({status: 'error', message: userLinkedinValidateData?.message}));
        }
    },[isUserLinkedinValidateSuccess, isUserLinkedinValidateError, userLinkedinValidateError]);
    
    useEffect(() => {
        if(isUserTwitterValidateSuccess) {
            if(userTwitterValidateData?.success === "true") {
                setIsUserValidationSuccess(true);
                setNotConnectedShareURL("");
                setUserNotConnectedMessage("");
            }
            else if(userTwitterValidateData?.success === "false") {
                // Redirect to Twitter URL with additional query parameter
                const twitterURL = process.env.REACT_APP_TWITTER_URL;
                const redirectUrl = `${twitterURL}&ObjectName=SocialShare`;  // Append query parameter

                setIsUserValidationSuccess(false);
                setNotConnectedShareURL(redirectUrl);
                setUserNotConnectedMessage(userTwitterValidateData?.message);
            }
        }
        if(isUserTwitterValidateError || userTwitterValidateError) {
            hideModal(props?.id);
            dispatch(updateAlertMessage({status: 'error', message: userTwitterValidateData?.message}));
        }
    },[isUserTwitterValidateSuccess, isUserTwitterValidateError, userTwitterValidateError]);

    useEffect(() => {
        if(isShareLinkedinPostSuccess) {
            dispatch(fullPageLoader(false));
            if(shareLinkedinPostData?.success === true) {
                dispatch(updateAlertMessage({status: 'success', message: shareLinkedinPostData?.message}));                
            }
            else {
                dispatch(updateAlertMessage({status: 'error', message: shareLinkedinPostData?.message}));
            }
        }
        if(isShareLinkedinPostError || shareLinkedinPostError) {
            dispatch(fullPageLoader(false));
            dispatch(updateAlertMessage({status: 'error', message: shareLinkedinPostData?.message}));
        }
    },[isShareLinkedinPostSuccess, isShareLinkedinPostError, shareLinkedinPostError]);

    useEffect(() => {
        if(isShareTwitterPostSuccess) {
            dispatch(fullPageLoader(false));
            if(shareTwitterPostData?.success === true) {
                dispatch(updateAlertMessage({status: 'success', message: shareTwitterPostData?.message}));                
            }
            else {
                dispatch(updateAlertMessage({status: 'error', message: shareTwitterPostData?.message}));
            }
        }
        if(isShareTwitterPostError || shareTwitterPostError) {
            dispatch(fullPageLoader(false));
            dispatch(updateAlertMessage({status: 'error', message: shareTwitterPostData?.message}));
        }
    },[isShareTwitterPostSuccess, isShareTwitterPostError, shareTwitterPostError]);

    const validationCheck = (network: string, shareContent: string) => {
        if(shareContent.trim().length === 0) {
            setIsSocialPostMaxLengthErrorFlag(true);
            setSocialPostMaxLengthKey('socialPost.required.message');
            return false;
        }
        else if(network === 'LinkedIn' && shareContent.trim().length > 3000) {
            setIsSocialPostMaxLengthErrorFlag(true);
            setSocialPostMaxLengthKey('maxlength3000.message');
            return false;
        }
        else if(network === 'WhatsApp' && shareContent.trim().length > 3000) {
            setIsSocialPostMaxLengthErrorFlag(true);
            setSocialPostMaxLengthKey('maxlength3000.message');
            return false;
        }
        else if(network === 'Twitter' && shareContent.trim().length > 280) {
            setIsSocialPostMaxLengthErrorFlag(true);
            setSocialPostMaxLengthKey('maxlength280.message');
            return false;
        }
        else {
            setIsSocialPostMaxLengthErrorFlag(false);
            setSocialPostMaxLengthKey('');
        }
        return true;
    }

    // This function will handle the POST API for social share (Currently handling for linkedin only)
    const submitSocialSharePost = (network: string) => {
        const isValidationChecked = validationCheck(network, shareContent);
        if(isValidationChecked === false) {
            return;
        }
        if(isSocialPostMaxLengthErrorFlag === true) {
            return;
        }
        if(props?.socialShareName === 'LinkedIn') {
            submitLinkedinPost();
        }
        else if(props?.socialShareName === 'WhatsApp') {
            shareToNetwork(network);
        }
        else if(props?.socialShareName === 'Twitter') {
            submitTwitterPost();
        }
        else if(props?.socialShareName === 'email') {
            
        }
    }    

    // This function will handle linkedin post API
    const submitLinkedinPost = () => {
        let payload: any;
        if(props?.postingOption === 'plain') {
            payload = {
                shareObject: props?.shareObject ? props?.shareObject : 'tile', 
                shareObjectId: props?.shareObjectId,
                shareCommentary: shareContent,
                shareMediaCategory: "None",
                visibility: socialShareVisibility === 0 ? 'PUBLIC' : 'CONNECTIONS'
            }
        }
        else if(props?.postingOption === 'ARTICLE') {
            payload = {
                shareObject: props?.shareObject ? props?.shareObject : 'tile', 
                shareObjectId: props?.shareObjectId,
                shareCommentary: shareContent,
                shareMediaCategory:"ARTICLE", 
                media: [
                            {
                                status: "READY",
                                description: {
                                    text: props?.socialDescription
                                },
                                MEDIA: "https://gptbluedev.bluecallom.ai",
                                MEDIATYPE: 'URL',
                                title: {
                                    text: "GPTBlue App - Improve Productivity"
                                }
                            }
                        ],
                visibility: socialShareVisibility === 0 ? 'PUBLIC' : 'CONNECTIONS'  
            }
        }
        else if(props?.postingOption === 'BASE64') {
            payload = {
                shareObject: props?.shareObject ? props?.shareObject : 'tile', 
                shareObjectId: props?.shareObjectId,
                shareCommentary: shareContent,
                shareMediaCategory: "IMAGE",
                media: [
                    {
                        status: "READY",
                        description: {
                            text: props?.socialDescription
                        },
                        MEDIA: props?.socialImage,
                        MEDIATYPE: 'BASE64',
                        title: {
                            text: "GPTBlue App - Improve Productivity"
                            }
                    }
                ],
                visibility: socialShareVisibility === 0 ? 'PUBLIC' : 'CONNECTIONS'  
            }
        }
        else {
            let originalUrl = '';
            const fileName = props?.socialImage?.split('/').pop();
            if(props?.promptURL && fileName === 'no-image.svg') {
                originalUrl = props?.promptURL;
            }
            else if(props?.socialImage) {
                originalUrl = props?.socialImage;
            }
            const mediaCategory = fileName === 'no-image.svg' ? 'ARTICLE' : 'IMAGE';
            payload = {
                shareObject: props?.shareObject ? props?.shareObject : 'tile', 
                shareObjectId: props?.shareObjectId,
                shareCommentary: shareContent,
                shareMediaCategory: mediaCategory,
                userpromptId: props?.userPromptId || '',
                media: 
                [
                    {
                        status: "READY",
                        description: {
                            text: props?.socialDescription
                        },
                        MEDIA: originalUrl,
                        MEDIATYPE: 'URL',
                        title: {
                            text: "GPTBlue App - Improve Productivity"
                            }
                    }
                ],
                visibility: socialShareVisibility === 0 ? 'PUBLIC' : 'CONNECTIONS'  
            }
        }
        shareLinkedinPostAPI(payload);
        dispatch(fullPageLoader(true));
        hideModal(props?.id);
        resetModalValues();
    }

    // This function will handle twitter post API
    const submitTwitterPost = () => {
        let payload: any;
        if(props?.postingOption === 'ARTICLE') {
            payload = {
				shareObject:props?.shareObject ? props?.shareObject : 'tile',
				shareObjectId: props?.shareObjectId,
				shareCommentary: shareContent,
                shareMediaCategory: "ARTICLE",
                media: [
                    {
                        status: "READY",
                        description: {
                            text: props?.socialDescription
                        },
                        MEDIA: "https://gptbluedev.bluecallom.ai",
                        MEDITYPE: 'URL',
                    }            
                ]
            }
        }
        else if(props?.postingOption === 'BASE64') {
            payload = {
                shareObject: props?.shareObject ? props?.shareObject : 'tile', 
                shareObjectId: props?.shareObjectId,
                shareCommentary: shareContent,
                shareMediaCategory: "IMAGE",
                media: [
                    {
                        status: "READY",
                        description: {
                            text: props?.socialDescription
                        },
                        MEDIA: props?.socialImage,
                        MEDIATYPE: 'BASE64',
                        title: {
                            text: "GPTBlue App - Improve Productivity"
                            }
                    }
                ]
            }
        }
        else {
            let originalUrl = '';
            const fileName = props?.socialImage?.split('/').pop();
            if(props?.promptURL && fileName === 'no-image.svg') {
                originalUrl = props?.promptURL;
            }
            else if(props?.socialImage) {
                originalUrl = props?.socialImage;
            }
            const mediaCategory = fileName === 'no-image.svg' ? 'ARTICLE' : 'IMAGE';
            payload = {
                shareObject: props?.shareObject ? props?.shareObject : 'tile', 
                shareObjectId: props?.shareObjectId,
                shareCommentary: shareContent,
                shareMediaCategory: mediaCategory, 
                userpromptId: props?.userPromptId || '',
                media: 
                [
                    {
                        status: "READY",
                        description: {
                            text: props?.socialDescription
                        },
                        MEDIA: originalUrl,
                        MEDIATYPE: 'URL',
                    }
                ],
            }
        }
        shareTwitterPostAPI(payload);
        dispatch(fullPageLoader(true));
        hideModal(props?.id);
        resetModalValues();
    }

    // This function is used to call the share API's except linkedin (That is handled in different function)
    const shareToNetwork = (network: string) => {
        if(isSocialPostMaxLengthErrorFlag === true) {
            return;
        }
        let url = '';
    
        switch (network) {
            case 'Facebook':
                url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareContent)}`;
                dispatch(fullPageLoader(true));
                hideModal(props?.id);
                gptBlueSocialShareAPI({
                    "socialnet": "Facebook", 
                    "shareObject": props?.shareObject ? props?.shareObject : 'tile', 
                    "shareObjectId": props?.shareObjectId 
                });
                break;
            case 'WhatsApp':
                url = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareContent)}`;
                dispatch(fullPageLoader(true));
                hideModal(props?.id);
                gptBlueSocialShareAPI({
                    "socialnet": "WhatsApp", 
                    "shareObject": props?.shareObject ? props?.shareObject : 'tile', 
                    "shareObjectId": props?.shareObjectId 
                });
                break;
            default:
                break;
        }
        window.open(url, '_blank');
        resetModalValues();
        hideModal(props?.id);
    };

    // Function to reset the values
    const resetModalValues = () => {
        props?.setSocialShareName('');
        setSocialShareId(0);
        setIsUserValidationSuccess(null);
        setNotConnectedShareURL("");
        setUserNotConnectedMessage("");
        setShareContent('');
        setIsSocialPostMaxLengthErrorFlag(false);
        setSocialPostMaxLengthKey('');
    }

    const handleShareContent = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setShareContent(event.target.value);
        validationCheck(props?.socialShareName, event.target.value);
    }

    return (
        <div className="modal fade modal-draggable" data-bs-backdrop="false" id={props.id} tabIndex={-1} aria-labelledby={`${props.id}Label`} aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={`${props.id}Label`}>
                            {props.socialShareName} {getTranslatedText('post.text',PageTranslation)}
                            {TranslationEditFlag && <EditTranslationIcon translationKey="post.text" translationType="text" />}
                        </h5>
                        <button type="button" className="btn-close" onClick={() => resetModalValues()} data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {/* Linkedin Validation API Loader */}
                        {isUserLinkedinValidateIsLoading && (
                            <>
                                <div className="text-center">
                                    <div className="spinner-border spinner-border-sm text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </>
                        )}
                        {/* Twitter Validation API Loader */}
                        {isUserTwitterValidateIsLoading && (
                            <>
                                <div className="text-center">
                                    <div className="spinner-border spinner-border-sm text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </>
                        )}
                        {/* If the particular authentication API is success */}
                        {isUserValidationSuccess === true ? (
                            <div className="row mb-3">
                                <div className="col-md-12 mb-3">
                                    <textarea  
                                        id="socialContent" 
                                        className={`form-control overflow-auto scrollbar ${isSocialPostMaxLengthErrorFlag ? 'is-invalid' : ''}`}
                                        rows={6}
                                        value={shareContent}
                                        onChange={(e) => handleShareContent(e)}
                                    />
                                    <div className="invalid-feedback">
                                        {isSocialPostMaxLengthErrorFlag === true && (
                                            <>
                                            <span dangerouslySetInnerHTML={{__html: getTranslatedText(socialPostMaxLengthKey,PageTranslation)}} />
                                            {TranslationEditFlag && <EditTranslationIcon translationKey={socialPostMaxLengthKey} translationType="text" />}
                                            </>
                                        )}
                                    </div>
                                </div>
                                {/* We need to show visibility only for Linkedin content */}
                                {socialShareId === 1 && (
                                    <div className="col-md-12 mb-3">
                                        <div className="row">
                                            <div className="col-md-3">
                                                {getTranslatedText('visibility.text',PageTranslation)}
                                                {TranslationEditFlag && <EditTranslationIcon translationKey="visibility.text" translationType="text" />}
                                            </div>
                                            <div className="col-md-4">
                                                <input type="range"
                                                    className="form-range bc-range"
                                                    min="0"
                                                    max="1"
                                                    step="1"
                                                    value={socialShareVisibility}
                                                    onChange={(e) => setSocialShareVisibility(Number(e.target.value))}
                                                />
                                                <div className="d-flex justify-content-between">
                                                    <TooltipComponent title={getTranslatedText('sharePublic.tooltip', PageTranslation)}>
                                                        <small>
                                                            <span dangerouslySetInnerHTML={{__html: getTranslatedText('public.text', PageTranslation)}} />
                                                        </small>
                                                    </TooltipComponent>
                                                    {TranslationEditFlag && 
                                                        <>
                                                        <EditTranslationIcon translationKey="public.text" translationType="text"/>
                                                        <EditTranslationIcon translationKey="sharePublic.tooltip" translationType="text"/>
                                                        </>
                                                    }
                                                    <TooltipComponent title={getTranslatedText('shareConnections.tooltip', PageTranslation)}>
                                                        <small>
                                                            <span dangerouslySetInnerHTML={{__html: getTranslatedText('connections.text', PageTranslation)}} />
                                                        </small>
                                                    </TooltipComponent>
                                                    {TranslationEditFlag &&
                                                        <>
                                                            <EditTranslationIcon translationKey="connections.text" translationType="text"/>
                                                            <EditTranslationIcon translationKey="shareConnections.tooltip" translationType="text"/>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* Only if user clicks on linkedin or twitter */}
                                {(socialShareId === 1 || socialShareId === 3) && (
                                    <>                                    
                                        {/* Show the image to the user if image or Base64 has been passed as props. */}
                                        {(props?.postingOption === 'image/video' || props?.postingOption === 'BASE64') && (
                                            <div className="text-center">
                                                <img className={`img-fluid ${props?.postingOption === 'image/video' ? 'h-15' : ''}`} src={props?.socialImage} alt={getTranslatedText('imageAlt.text',PageTranslation)} />
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        ): (
                            <div className="text-center text-danger">
                                {userNotConnectedMessage}
                            </div>
                        )}
                    </div>
                    <div className="modal-footer">
                        <TooltipComponent title={getTranslatedText('close.tooltip', PageTranslation)}>
                            <button className="btn btn-secondary" type="button" data-bs-dismiss="modal" onClick={() => resetModalValues()}>
                                <span dangerouslySetInnerHTML={{__html: getTranslatedText('close.text', PageTranslation)}}/>
                            </button>
                        </TooltipComponent>
                        {TranslationEditFlag && (
                            <>
                                <EditTranslationIcon translationKey="close.text" translationType="text"/>
                                <EditTranslationIcon translationKey="close.tooltip" translationType="tooltip"/>
                            </>
                        )}
                        {isUserValidationSuccess === true && (
                            <>
                                <TooltipComponent title={getTranslatedText('post.tooltip', PageTranslation)}>
                                    <button className="btn btn-primary" type="submit" onClick={() => submitSocialSharePost(props?.socialShareName)}>
                                        <span dangerouslySetInnerHTML={{__html: getTranslatedText('post.text', PageTranslation)}}/>
                                    </button>
                                </TooltipComponent>
                                {TranslationEditFlag && (
                                    <>
                                        <EditTranslationIcon translationKey="post.text" translationType="text"/>
                                        <EditTranslationIcon translationKey="post.tooltip" translationType="tooltip"/>
                                    </>
                                )}
                            </>
                        )}
                        {isUserValidationSuccess === false && (
                            <>
                                <TooltipComponent title={getTranslatedText('connect.tooltip', PageTranslation)}>
                                <button 
                                    className="btn btn-primary" 
                                    type="button" 
                                    onClick={() => {
                                        window.open(notConnectedShareURL, "_blank");
                                        hideModal(props?.id);
                                        resetModalValues();
                                    }}
                                    >
                                    {getTranslatedText('connect.text', PageTranslation)}
                                    </button>
                                </TooltipComponent>
                                {TranslationEditFlag && (
                                    <>
                                        <EditTranslationIcon translationKey="connect.text" translationType="text"/>
                                        <EditTranslationIcon translationKey="connect.tooltip" translationType="tooltip"/>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SocialSharePost;